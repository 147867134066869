import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { Grid, Headline, Button, LoginForm, MainWrapper } from 'msp-components'
import {
  useCustomer,
  useEinstein,
  useNavigation,
  useCookie
} from 'msp-integrations'
import PageLayout from '@/components/cms/page-layout'
import Advantages from '@/components/atoms/Advantages/Advantages'
import Seo from '@/components/seo'

const LoginPage = ({ page }: any) => {
  const { formatMessage } = useIntl()
  const { isMarketingAllowed } = useCookie()
  const navigate = useNavigation()
  const customer = useCustomer()
  const location = useLocation()
  const einstein = useEinstein()
  const query = queryString.parse(location.search || '')

  // If customer is registered push to account page
  useEffect(() => {
    if (customer.authType != null && customer.isRegistered) {
      if (query?.redirectUrl) {
        navigate(query.redirectUrl as string)
      } else {
        navigate('/account')
      }
    }
  }, [customer])

  useEffect(() => {
    einstein.sendViewPage(location.pathname)
  }, [isMarketingAllowed])

  const styling = {
    main: 'lg:p-10 xl:py-28 w-full h-full bg-machine1-25 mb-0.5 lg:mb-0 bg-no-repeat bg-cover bg-center',
    grid: 'grid-rows-2 lg:grid-cols-2 lg:grid-rows-none',
    container: 'items-center p-8 pb-12 lg:p-10 xl:pt-24 xl:pb-60',
    wrapper: 'w-full lg:w-fit lg:w-moz-fit lg:mx-auto',
    title: 'h-16 inline-flex items-end',
    subtitle: 'font-Roboto text-20 leading-28 tracking-0 lg:mt-9 lg:mb-8',
    textOrientation: 'w-full text-left whitespace-nowrap'
  }

  return (
    <div className='page-main-wrapper page-bg-image'>
      <Seo
        title={
          page.found && page?.meta?.title
            ? page?.meta?.title
            : formatMessage({
                id: 'msp.login.seo.title',
                defaultMessage: 'Login'
              })
        }
        description={
          page.found && page?.meta?.description
            ? page?.meta?.description
            : formatMessage({
                id: 'msp.login.seo.description',
                defaultMessage: 'Login into DARQ'
              })
        }
        keywords={
          page.found && page?.meta?.keywords ? page?.meta?.keywords : ''
        }
        canonicalPath={'/login'}
      />
      <MainWrapper>
        <Grid wrapperStyles='page-grid'>
          <div className={`page-col ${styling.container} ml-auto bg-white`}>
            <div className={`${styling.wrapper}`}>
              <Headline
                tag='h1'
                className={`${styling.textOrientation} ${styling.title} text-44 sm:text-52 leading-40 md:leading-44 tracking-0 h-16 font-normal max-md:mb-6`}
              >
                {formatMessage({
                  id: 'welcome.login.title',
                  defaultMessage: 'Welcome'
                })}
              </Headline>
              <Headline
                tag='h2'
                className={`${styling.subtitle} ${styling.textOrientation} headline-h6`}
              >
                {formatMessage({
                  id: 'welcome.login.subtitle',
                  defaultMessage: 'Do you already have an account?'
                })}
              </Headline>
              <div className='w-full lg:w-64'>
                <LoginForm
                  className='mt-11 w-full lg:mt-0'
                  redirectTo={query?.redirectUrl as string}
                />
              </div>
            </div>
          </div>
          <div className={`page-col ${styling.container} mr-auto bg-black`}>
            <div className={`${styling.wrapper}`}>
              <Headline
                tag='h1'
                className={`${styling.textOrientation} ${styling.title} text-28 leading-40 md:leading-44 tracking-0 text-white max-md:mb-6 md:mb-8`}
              >
                {formatMessage({
                  id: 'advantages.title',
                  defaultMessage: 'New at AVOURY?'
                })}
              </Headline>
              <Advantages />
              <Button
                onClick={() => navigate('/registration')}
                className={
                  'text-10 mr-0 mt-11 w-full border border-white bg-black p-4 lg:w-80'
                }
              >
                {formatMessage({
                  id: 'welcome.login.register',
                  defaultMessage: 'Register now'
                })}
              </Button>
            </div>
          </div>
        </Grid>
      </MainWrapper>
    </div>
  )
}

const LoginPageWrapper = (props: any) => (
  <PageLayout {...{ ...props, ChildPage: LoginPage }} />
)

export default LoginPageWrapper
