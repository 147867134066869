import React from 'react'
import { useIntl } from 'react-intl'
import { Headline } from 'msp-components'
import { CheckIcon } from '@heroicons/react/solid'
import * as S from './Advantages.styles'

type AdvantagesProps = {
  styles?: {
    title?: string
    list?: string
    listItem?: string
    text?: string
    iconFill?: string
  }
}

const Advantages = ({ styles }: AdvantagesProps) => {
  const { formatMessage } = useIntl()

  const styling = {
    title: styles?.title || S.AdvantagesStylesTitle,
    list: styles?.list || S.AdvantagesStylesList,
    listItem: styles?.listItem || S.AdvantagesStylesListItem,
    text: styles?.text || S.AdvantagesStyleText,
    iconFill: styles?.iconFill || 'white'
  }

  const advantages = [
    formatMessage({
      id: 'msp.advantages.list.1',
      defaultMessage: 'Order History & Management'
    }),
    formatMessage({
      id: 'msp.advantages.list.2',
      defaultMessage: 'Personalized offers'
    }),
    formatMessage({
      id: 'msp.advantages.list.3',
      defaultMessage: 'Easy reorder'
    }),
    formatMessage({
      id: 'msp.advantages.list.4',
      defaultMessage: 'Wishlist'
    }),
    formatMessage({
      id: 'msp.advantages.list.5',
      defaultMessage: 'Order with Invoice'
    })
  ]

  return (
    <>
      <Headline tag='h3' className={styling.title}>
        {formatMessage({
          id: 'msp.registration.title.advantages',
          defaultMessage: 'Your advantages'
        })}
      </Headline>
      <ul className={styling.list}>
        {advantages.map((item, idx) => (
          <li key={idx} className={styling.listItem}>
            <CheckIcon
              fill={styling.iconFill}
              width='20px'
              height='20px'
              className='my-auto shrink-0'
            />
            <span className={styling.text}>{item}</span>
          </li>
        ))}
      </ul>
    </>
  )
}

export default Advantages
